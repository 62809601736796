#footer {
  background-color: #232f3e;
  color: #ffffff;
  font-family: Amazon Ember, Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif;
  padding: 0 4rem;
}

#footer .awsui-util-f-r {
  color: #aab7b8;
  font-size: 12px;
}

#footer a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}
