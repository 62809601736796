@import '~@amzn/awsui-components-react/index.css';
@import './assets/style/header.scss';
@import './assets/style/footer.scss';
@import './views/forecaster/network-configurations/style/table.scss';

:root {
  --meridian-color-purple-500: #C54FB4;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
