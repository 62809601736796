#header {
  align-items: center;
  background-color: var(--awsui-color-aws-squid-ink) !important;
  color: var(--awsui-color-grey-300) !important;
  font-family: Amazon Ember, Helvetica Neue, Roboto, Arial, sans-serif;
  height: 42px;
  padding: 0 1em;
}

.awsui-toggle {
  color: #d5dbdb !important;
}
